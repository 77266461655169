/* React Swiper Carousel Custom css */
.heightFull,
.heightFull .carouselWrapper,
.heightFull .carouselWrapper>.swiper,
.heightFull .carouselWrapper>.swiper>.swiper-wrapper,
.heightFull .carouselWrapper>.swiper>.swiper-wrapper .swiper-slide>div,
.heightFull .carouselWrapper>.swiper>.swiper-wrapper .swiper-slide>a,
.heightFull .carouselWrapper>.swiper>.swiper-wrapper .swiper-slide>a>.group {
  height: 100%;
}

.heightFull .carouselWrapper>.swiper>.swiper-wrapper .swiper-slide>a {
  display: inline-flex;
}

.swiper-wrapper .swiper-slide {
  height: auto;
}

.swiper .swiper-button-prev,
.swiper .swiper-button-next {
  display: none;
}

.swiper-button-disabled {
  cursor: not-allowed;
  opacity: 0;
  visibility: hidden;
}

.swiper-button-disabled:hover {
  color: inherit;
}

.carouselWrapper .swiper .swiper-pagination-bullets .swiper-pagination-bullet {
  width: 14px;
  height: 8px;
  border: 0;
  background-color: #fff;
  border-radius: 8px;
  transition: all 0.3s ease-in-out;
  opacity: 0.4;
}

.carouselWrapper .swiper .swiper-pagination-bullets {
  bottom: 20px;
}

.carouselWrapper .swiper .swiper-pagination-bullets .swiper-pagination-bullet.swiper-pagination-bullet-active {
  width: 18px;
  opacity: 1;
}

.carouselWrapper.dotsCircleBlack .swiper .swiper-pagination-bullets .swiper-pagination-bullet {
  background-color: #000;
}

#productGalleryThumbs,
#reviewGalleryThumbs {
  margin-left: -6px;
  margin-right: -6px;
  padding-bottom: 2px;
}

#productGalleryThumbs .swiper-wrapper .swiper-slide,
#reviewGalleryThumbs .swiper-wrapper .swiper-slide {
  margin-left: 6px;
  margin-right: 6px;
  width: calc(25% - 12px) !important;
}

@media screen and (min-width: 1280px) {

  #productGalleryThumbs,
  #reviewGalleryThumbs {
    margin-left: -8px;
    margin-right: -8px;
  }

  #productGalleryThumbs .swiper-wrapper .swiper-slide,
  #reviewGalleryThumbs .swiper-wrapper .swiper-slide {
    margin-left: 8px;
    margin-right: 8px;
    width: calc(25% - 16px) !important;
  }
}

@media screen and (min-width: 1536px) {

  #productGalleryThumbs,
  #reviewGalleryThumbs {
    margin-left: -10px;
    margin-right: -10px;
  }

  #productGalleryThumbs .swiper-wrapper .swiper-slide,
  #reviewGalleryThumbs .swiper-wrapper .swiper-slide {
    margin-left: 10px;
    margin-right: 10px;
    width: calc(25% - 20px) !important;
  }
}


#hero-slider .swiper-pagination-bullet {
  border: 2px solid #7b7d7e;
  background-color: #fff !important;
  opacity: 1 !important;
  margin-right: 10px !important;
  padding: 6px;
}

#hero-slider .swiper-pagination-bullet-active {
  background-color: #1588f3 !important;
  border: 2px solid #fff;
}

#hero-slider .autoplay-progress {
  position: absolute;
  right: 16px;
  bottom: 30px;
  z-index: 10;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 14px;
  color: var(--swiper-theme-color);
}

#hero-slider .autoplay-progress svg {
  --progress: 0;
  position: absolute;
  left: 0;
  top: 0px;
  z-index: 10;
  width: 100%;
  height: 100%;
  stroke-width: 4px;
  stroke: var(--swiper-theme-color);
  fill: none;
  stroke-dashoffset: calc(125.6 * (1 - var(--progress)));
  stroke-dasharray: 125.6;
  transform: rotate(-90deg);
}

#hero-slider .swiper-pagination {
  display: flex;
  flex-direction: column;
  gap: 10px;
  bottom: 33% !important;
  left: calc(100% - 60px) !important;
}

@media (max-width: 639px) {
  #hero-slider .swiper-pagination {
    display: flex;
    flex-direction: row;
    gap: 0px;
    bottom: 0% !important;
    justify-content: center;
    left: 0px !important;
  }

  #hero-slider .swiper-pagination-bullet {
    border: 1px solid #7b7d7e;
    background-color: #fff !important;
    opacity: 1 !important;
    margin-right: 5px !important;
    padding: 2px;
  }

  #hero-slider .autoplay-progress {

    width: 30px;
    height: 30px;
    font-size: 10px;
  }

  #hero-slider .swiper-pagination-bullet-active {
    background-color: #1588f3 !important;
    border: none;
  }

  #hero-slider .slide-content {
    background-position-x: 80%;
  }
}

/* Drawer */
.header {
  display: block;
  margin: 0 auto;
  width: 100%;
  max-width: 100%;
  box-shadow: none;
  background-color: #FC466B;
  position: fixed;
  height: 60px!important;
  overflow: hidden;
  z-index: 10;
}
.main {
  margin: 0 auto;
  display: block;
  height: 100%;
  margin-top: 60px;
}
.mainInner{
  display: table;
  height: 100%;
  width: 100%;
  text-align: center;
}
.mainInner div{
  display:table-cell;
  vertical-align: middle;
  font-size: 3em;
  font-weight: bold;
  letter-spacing: 1.25px;
}
#sidebarMenu {
  height: 100%;
  position: fixed;
  bottom: 0;
  width: 250px;
  margin-top: 60px;
  transform: translateY(780px);
  transition: transform 250ms ease-in-out;
  background: linear-gradient(180deg, #FC466B 0%, #3F5EFB 100%);
  -webkit-transform: translateY(780px);
  -moz-transform: translateY(780px);
  -ms-transform: translateY(780px);
  -o-transform: translateY(780px);
}
.sidebarMenuInner{
  margin:0;
  padding:0;
  border-top: 1px solid rgba(255, 255, 255, 0.10);
}
.sidebarMenuInner li{
  list-style: none;
  color: #fff;
  text-transform: uppercase;
  font-weight: bold;
  padding: 20px;
  cursor: pointer;
  border-bottom: 1px solid rgba(255, 255, 255, 0.10);
}
.sidebarMenuInner li span{
  display: block;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.50);
}
.sidebarMenuInner li a{
  color: #fff;
  text-transform: uppercase;
  font-weight: bold;
  cursor: pointer;
  text-decoration: none;
}
input[type="checkbox"]:checked ~ #sidebarMenu {
  transform: translateY(0);
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
}

input[type=checkbox] {
  transition: all 0.3s;
  box-sizing: border-box;
  display: none;
}
.sidebarIconToggle {
  transition: all 0.3s;
  box-sizing: border-box;
  cursor: pointer;
  position: absolute;
  z-index: 99;
  height: 100%;
  width: 100%;
  top: 22px;
  left: 15px;
  height: 22px;
  width: 22px;
}
.spinner {
  transition: all 0.3s;
  box-sizing: border-box;
  position: absolute;
  height: 3px;
  width: 100%;
  background-color: #fff;
}
.horizontal {
  transition: all 0.3s;
  box-sizing: border-box;
  position: relative;
  float: left;
  margin-top: 3px;
}
.diagonal.part-1 {
  position: relative;
  transition: all 0.3s;
  box-sizing: border-box;
  float: left;
}
.diagonal.part-2 {
  transition: all 0.3s;
  box-sizing: border-box;
  position: relative;
  float: left;
  margin-top: 3px;
}
input[type=checkbox]:checked ~ .sidebarIconToggle > .horizontal {
  transition: all 0.3s;
  box-sizing: border-box;
  opacity: 0;
}
input[type=checkbox]:checked ~ .sidebarIconToggle > .diagonal.part-1 {
  transition: all 0.3s;
  box-sizing: border-box;
  transform: rotate(135deg);
  margin-top: 8px;
}
input[type=checkbox]:checked ~ .sidebarIconToggle > .diagonal.part-2 {
  transition: all 0.3s;
  box-sizing: border-box;
  transform: rotate(-135deg);
  margin-top: -9px;
}
  
  
  